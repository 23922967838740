import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { m } from 'framer-motion';
import { useSnackbar } from 'notistack';
// @mui
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { GET_SITE_LOCK, SAVE_SITE_LOCK } from 'src/api/event';

// ----------------------------------------------------------------------

export default function SiteLockPopover() {
  const { data } = useQuery(GET_SITE_LOCK, {
    onCompleted(d) {
      setSetting(d.siteLock);
    }
  });
  const { enqueueSnackbar } = useSnackbar();
  const [setting, setSetting] = useState({
    id: 'site_lock',
    locked: false,
    message: "",
  });
  const iconText = useMemo(() => data?.siteLock?.locked ? 'solar:lock-keyhole-bold' : 'solar:lock-keyhole-unlocked-bold-duotone', [data]);
  const popover = usePopover();

  const [saveSiteLock, { loading }] = useMutation(SAVE_SITE_LOCK);

  const handleSave = useCallback(async () => {
    try {
      await saveSiteLock({
        variables: {
          input: {
            locked: setting?.locked,
            message: setting?.message,
          },
        },
      });
      enqueueSnackbar('Site lock saved', { variant: 'success' });
      popover.onClose();
    } catch(error) {
      console.error(error);
      enqueueSnackbar('Error saving site lock', { variant: 'error' });
    }
  }, [enqueueSnackbar, saveSiteLock, popover, setting]);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
        }}
      >
        <Iconify icon={iconText} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={() => {
        setSetting(data?.siteLock || {
          id: 'site_lock',
          locked: false,
          message: "",
        });
        popover.onClose();
      }} sx={{ width: 400, p: 0 }}>
        <Stack spacing={2} sx={{ p: 2, pb: 1.5 }}>
          <TextField
            fullWidth
            label="Officer Message"
            type="text"
            autoFocus
            value={setting?.message}
            sx={{ mt: 1 }}
            onChange={(event) => {
              setSetting((c: any) => ({
                ...c,
                message: event.target.value,
              }));
            }}
          />
          <FormGroup>
            <FormControlLabel control={<Switch checked={setting?.locked} onChange={(_e, checked) => {
              setSetting((c: any) => ({
                ...c,
                locked: checked,
              }));
            }} />} label="Site locked" />
          </FormGroup>
          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            onClick={handleSave}
          >Save</LoadingButton>
        </Stack>
      </CustomPopover>
    </>
  );
}
