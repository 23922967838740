import { gql } from "@apollo/client";

export const REMOVE_EVENT = gql`
  mutation RemoveEvent($id: String!) {
    removeEvent(id: $id)
  }
`;

export const EVENT_UPLOAD_URL = gql`
  mutation EventUploadUrl {
    eventAttachmentUploadUrl {
      attachmentPath
      uploadUrl
    }
  }
`;

export const GET_ALL_ASSIGNMENTS = gql`
query Assignments($input: AssignmentSearchInput) {
  assignments(input: $input) {
    total
    items {
      id
      name
      availableShifts
      requiredSkills {
        id
        name
      }
      ranks {
        id
        name
      }
    }
  }
}
`;

export const GET_ALL_SHIFTS = gql`
query Shift($assignmentId: String!) {
  assignment(id: $assignmentId) {
    shifts {
      id
      shiftId
      status
      userId
      startTime
      endTime
    }
  }
}
`;

export const SAVE_SITE_LOCK = gql`
mutation SiteLockMutation($input: UpsertSiteLock) {
  siteLock(input: $input) {
    id
    locked
    message
  }
}
`;

export const NOTIFY_OFFICERS = gql`
mutation SendNotification {
  sendNotification
}
`;

export const GET_SITE_LOCK = gql`
query SiteLock {
  siteLock {
    id
    locked
    message
  }
}
`;

export const ASSIGN_SHIFT = gql`
mutation ApproveLottoAssignment($id: String!, $shiftId: String!, $userId: String!) {
  approveLottoAssignment(id: $id, shiftId: $shiftId, userId: $userId)
}
`;

export const UNASSIGN_SHIFT = gql`
mutation UnassignShift($id: String!, $shiftId: String!, $userId: String!) {
  rejectLottoAssignment(id: $id, shiftId: $shiftId, userId: $userId)
}
`;

export const GET_LOTTERY_DATES = gql`
  query GetLotteryDates($input: SearchInput!) {
    lotteryCounters(input: $input) {
      items {
        id
        counter
        start
        end
      }
      total
    }
  }
`;

export const GET_ALL_REQUESTS = gql`
query LottoRequest($input: ShiftLottoRequestsSearchInput!, $shiftStart: String, $timezoneOffset: Int) {
  shiftLottoRequests(input: $input) {
    items {
      id
      status
      order
      userId
      shiftGroupId
      user(shiftStart: $shiftStart, timezoneOffset: $timezoneOffset) {
        id
        name
        firstName
        lastName
        numberOfShiftsThisWeek
        member {
          tier
          tierUntil
          tierResetOnNextLottery
          contactInfo {
            primaryEmail {
              address
            }
          }
        }
        # numberOfShiftsThisWeek
      }
    }
  }
}
`;

export const GET_USER = gql`
query User($userId: String!, $shiftStart: String, $timezoneOffset: Int) {
  user(id: $userId, shiftStart: $shiftStart, timezoneOffset: $timezoneOffset) {
    id
    name
    shiftsThisWeek {
      id
      startTime
      endTime
    }
    member {
      pNumber
      tier
      tierUntil
      tierResetOnNextLottery
      rank {
        id
        name
      }
      memberSkills {
        id
        skill {
          id
          name
        }
      }
      maxShifts
      availability {
        monday {
          available
          times {
            startTime
            endTime
          }
        }
        tuesday {
          available
          times {
            startTime
            endTime
          }
        }
        wednesday {
          available
          times {
            startTime
            endTime
          }
        }
        thursday {
          available
          times {
            startTime
            endTime
          }
        }
        friday {
          available
          times {
            startTime
            endTime
          }
        }
        saturday {
          available
          times {
            startTime
            endTime
          }
        }
        sunday {
          available
          times {
            startTime
            endTime
          }
        }
      }

    }
  }
}
`;

export const GET_ALL_EVENTS = gql`
  query Events($input: EventSearchInput) {
    events(input: $input) {
      items {
        id
        referenceId
        name
        startTime
        endTime
        availableShifts
      }
      total
    }
  }
`;

export const GET_EVENT = gql`
  query GetEvent($id: String!) {
    event(id: $id) {
      id
      name
      attachments
      status
      venueId
      notes
      assignmentIds
      attachmentPaths
      notifyWhenFullyStaffed
      scheduledFirstComeFirstServe
      startTime
      endTime

      venue {
        id
        name
        address
        city
        state
        zip
        notes
      }

      assignments {
        id
        name
        ranks {
          id
          name
        }

        requiredSkills {
          id
          name
        }

        shifts {
          id
          shiftId
          status
          startTime
          endTime
          userId
        }
      }

    }
  }
`;

export const UPSERT_EVENT = gql`
  mutation UpsertEvent($input: UpsertEvent!) {
    event(event: $input) {
      id
    }
  }
`;

export type EventTableFilters = {
  name: string;
};

export type EventTableFilterValue = string | string[];
