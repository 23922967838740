import { useQuery } from '@apollo/client';
// @mui
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
// components
import { GET_LOTTERY_DATES } from 'src/api/event';
import { LotteryCounter } from 'src/__generated__/resolvers-types';
import { useAuthContext } from 'src/auth/hooks';
import { convertDate, dateTimeFormat } from 'src/hooks/use-datejs';
import { useSettingsContext } from 'src/components/settings';

// ----------------------------------------------------------------------

export default function LotterySelect() {
  const { user } = useAuthContext();
  const { lottery, onUpdate } = useSettingsContext();

  const handleChange = (event: SelectChangeEvent) => {
    onUpdate('lottery', event.target.value as any);
  };

  const { data: lotteryRunDates } = useQuery(GET_LOTTERY_DATES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      input: {
        orderBy: 'counter',
        order: 'desc',
        limit: 100,
      },
    },
  });

  const runDateOptions = (lotteryRunDates?.lotteryCounters?.items || []).slice(0, 10).map(
    (item: LotteryCounter) => ({
      label: `${convertDate({ date: item.start, user }).format(dateTimeFormat)} - ${convertDate({
        date: item.end,
        user,
      }).format(dateTimeFormat)}`,
      value: item.counter,
    })
  );

  return (
    <Box width='350px'>
      <FormControl fullWidth>
        <InputLabel>Lottery</InputLabel>
        <Select
          labelId="Lottery"
          value={lottery as any}
          label="Lottery"
          onChange={handleChange}
        >
          {runDateOptions.map((option: any) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}
